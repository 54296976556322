<template>
  <NuxtLoadingIndicator color="#5accf0" />
  <div class="flex flex-col justify-between h-screen">
    <Navigation v-if="!isMobile" />
    <MobileNavigation v-else />

    <main
      id="app"
      class="relative w-full mx-auto mb-auto"
      style="max-width: 2560px"
    >
      <slot />
    </main>

    <Newsletter v-if="!isStorePage" />

    <CuratorWidget v-if="isCuratorVisible && curatorEnable" />

    <Footer v-if="!isStorePage" />

    <ModalsContainer />
  </div>
</template>

<script setup>
import { ModalsContainer } from "vue-final-modal";
import { useMainStore } from "@/store/index";
import { useLocaleStore } from "@/store/locales";

// use methods
const route = useRoute();
const mainStore = useMainStore();
const localeStore = useLocaleStore();
const nuxtApp = useNuxtApp();

//data
const $i18nPath = nuxtApp.$i18nPath;
const isMobile = ref(false);
const curatorEnable = ref(false);
const windowWidth = ref(0);

onServerPrefetch(async () => {
  // ✅ this will work
  await mainStore.fetchInitial();
});

// computed
const isStorePage = computed(() => {
  return route.path.startsWith($i18nPath("/stores"));
});

const isAboutUsPage = computed(() => {
  return route.path.startsWith($i18nPath("/about-us"));
});

const isHomePage = computed(() => {
  return route.name == "country";
});

const isCuratorVisible = computed(() => {
  return isHomePage.value || isAboutUsPage.value;
});

onMounted(async () => {
  if (!process.browser) return false;
  window.onscroll = () => {
    if (document.documentElement.scrollTop > 5) {
      if (!curatorEnable.value) {
        curatorEnable.value = true;
        window.onscroll = null;
      }
    }
  };

  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 1023) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }

  await nextTick();
  window.addEventListener("resize", onResize);
  nuxtApp.$loadIubendaCookieConsent();
  nuxtApp.$loadGTM();

  if (!isStorePage.value && nuxtApp.$loadIntercom) {
    nuxtApp.$loadIntercom();
  }
});

onBeforeUnmount(() => {
  window.onscroll = null;
  window.removeEventListener("resize", onResize);
});

const onResize = () => {
  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 1023) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }
};
/***  head setup ***/
const baseAppUrl = nuxtApp.$config.public.APP_URL;
const styles = [];

// eslint-disable-next-line
let metaLinks = [
  { rel: "alternate", href: `${baseAppUrl}`, hreflang: "x-default" },
  {
    rel: "canonical",
    href: `${baseAppUrl}${route.path.substring(1)}`,
  },
];
// Add alternate urls
mainStore.sites
  .filter((site) => site.slug === mainStore.country)
  .forEach((site) => {
    site.urls.forEach((item) => {
      metaLinks.push({
        rel: "alternate",
        href: item.url,
        hreflang: item.locale_code,
      });
    });
  });

useHead({
  titleTemplate: "%s | Morbidelli",
  link: metaLinks,
  style: styles,
  htmlAttrs: {
    lang: mainStore.locale,
    dir: mainStore.isRtl ? "rtl" : "ltr",
  },
});
</script>